import React from "react";
import { shortenAddress } from "../../utils/helpers/ShortenAddress";
import wallet from "../../assets/wallet.svg";
import { calculateTimeElapsed } from "../../utils/helpers/TimeElapsed";
import { Chip, IconButton, Tooltip } from "@material-tailwind/react";
import { PencilIcon } from "@heroicons/react/24/solid";

const AdminOrderCard = ({ order, setOrderToManage, setPage }) => {
  return (
    <div className=" border-b-[0.5px] py-6 px-6 border-gray-700 flex items-center font-VitoReg">
      <div className="flex items-center gap-2 w-[25%]">
        <img src={wallet} alt="wallet" className="h-12 w-12" />

        <span className="font-VitoBold">
          {shortenAddress(order.walletAddress)}
        </span>
      </div>

      <div className="w-[25%] flex justify-center">
        {calculateTimeElapsed(order.timestamp)}
      </div>

      <div className="flex justify-center w-[25%]">
        {order.orderStatus === "shipped" ? (
          <Chip
            size="sm"
            variant="ghost"
            value={"shipped"}
            color={"green"}
            className="text-green-300"
          />
        ) : (
          <Chip
            size="sm"
            variant="ghost"
            value={"processing"}
            color={"yellow"}
            className="text-yellow-300 font-VitoMed"
          />
        )}
      </div>

      <div
        onClick={() => {
          setOrderToManage(order);
          setPage(2);
        }}
        className="flex justify-end w-[25%] group"
      >
        <Tooltip content="View/Edit Data">
          <IconButton
            variant="text"
            className="group-hover:bg-gray-400 group-hover:bg-opacity-20 transition-all duration-100"
          >
            <PencilIcon className="h-4 w-4 text-white" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default AdminOrderCard;
