import React, { useState } from "react";
import placeholder from "../../assets/placeholder.png";
import ClaimPhases from "../ClaimPhases/ClaimPhases";

const ClaimArea = () => {
  const [claimPhase, setClaimPhase] = useState(1);

  return (
    // <div className="min-h-screen  bg-[#F9F6EE] w-full">
    //   <div className="flex flex-col lap:flex-row items-center">
    //     <div className="w-full lap:w-[50%] h-[400px] lap:min-h-screen bg-claim bg-center bg-cover " />
    //     <ClaimPhases setClaimPhase={setClaimPhase} claimPhase={claimPhase} />
    //   </div>
    // </div>
    <div className="flex flex-col min-h-screen bg-[#F9F6EE] w-full overflow-hidden">
      <div className="flex-grow flex">
        <div
          className="w-full min-h-screen bg-claim bg-center bg-cover"
          style={{ flex: "1" }}
        />
        <ClaimPhases setClaimPhase={setClaimPhase} claimPhase={claimPhase} />
      </div>
    </div>
  );
};

export default ClaimArea;

/*
F9F6EE BONE
FFFAFA SNOW
FAFAFA ANTIFLESH
FFFFF0 IVORY
*/
