export const calculateTimeElapsed = (unixTimestamp) => {
  const now = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
  const secondsElapsed = now - unixTimestamp;

  if (secondsElapsed < 60) {
    return `${secondsElapsed} second${secondsElapsed !== 1 ? "s" : ""} ago`;
  } else if (secondsElapsed < 3600) {
    const minutesElapsed = Math.floor(secondsElapsed / 60);
    return `${minutesElapsed} minute${minutesElapsed !== 1 ? "s" : ""} ago`;
  } else if (secondsElapsed < 86400) {
    const hoursElapsed = Math.floor(secondsElapsed / 3600);
    return `${hoursElapsed} hour${hoursElapsed !== 1 ? "s" : ""} ago`;
  } else {
    const daysElapsed = Math.floor(secondsElapsed / 86400);
    return `${daysElapsed} day${daysElapsed !== 1 ? "s" : ""} ago`;
  }
};
