export const Addresses = [
  // Admins
  "0x7667A06a7b0c7E3185da4FA4e452A9d1cd24D9dB", // Rude
  "0xD89522b2C23137C356FbF9090341Fd74432FA4A2", // Tom
  "0xcC79596ef8010C182EeC2B77161FF34fc973CF40", // TopDog
  // Users
  "0x98B922021bB2aa87697E12030d60a1cc8b57c975",
  "0x1Ce1a58Cd5705F386e07cC9d49B8812C5ee40b3f",
  "0x3Fb47F7db5ab1a3Af5aC3417a77CB7DBe70ee6C5",
  "0xA7cC5BAd3d643b216731Dcf281a547B9379a2e30",
  "0x093965b128fe34E6d9167F41d3a2d70485C4bEBa",
  "0x0dDCf572D23AefCF2d2Ee75d71783bdF6b1F8980",
  "0x104174aFC435c47dB77bdBC953aCAC5cdDDa9d47",
  "0x1be393F5248A15A9E3e6c04Dcbbf9eDD7FBd7Cf5",
  "0xBB9BAddF7Cb518e993B68E242f63b9e44e1A9187",
  "0xB3db059c988111706A2d9360860F2BC27bf4A3fe",
  "0xCE44C79EFA8ae696e9907464d5C5743ecc866258",
  "0x7b60e46eba142b7e95e967c1e34cfb862385ee53",
  "0x998404D5CD6e83d20d47D2D3259718Ca9Bad7291",
  "0xB617b7895570cE35496C6450761b942F7c51fdA7",
  "0x98973F947d09740092a2bE5DbD43cE8cf3ace441",
  "0x8F81Ac84f240b215E2e9A67b6d520612C282B844",
  "0x54e244d5467ab1f80c2011712d99a272c32d7d35",
  "0x146a47F7205B720aD8f030A2FAEaF91E24c233d2",
  "0xC78bDD391C026979CC00d8fcAE663F0E5e3b99C5",
  "0x81dE17E9d4A2a3417c83C3e7dE84af8be91c416c",
  "0x764303d94846475D6121e47C79709aa049958D95",
  "0xB4Be85887D68A3dFDd5e9826A5b7744379FD34E4",
  "0xA5410E871f83914f2B53F2Bf7Cc3c883b210c03A",
  "0x9c1218103846887b469dd55b36De440CFa42045D",
  "0x02082f45eE3A5d224e0e0d5E79FCD271FEedBf8A",
  "0xA3A7a00525c1aD0fd8274612B89E23afa1289688",
  "0x50c9e0AE3dba5B0560fA9dBD7C654D901b952187",
  "0x6BbDA51e68D03f510601d0c3dbb8fF463465F9df",
  "0xAbaaD19d9743d44AE77640aB2Ce42342F61C9B87",
  "0x49942D3743f25410Cea79cA8d11C82AEE9e4FD5d",
  "0x934C713bFF70571267937C6125a212c4be423F4d",
  "0x532363B6355A3f9604EB246AAE112F2e25C3d0B9",
  "0x8C5A6AA4d0e0Aa837a5C6bD505404a5459969871",
  "0x8F295699fcdd3794B584F82cEA6586e4657613E6",
  "0xc36912c01C37bA9888f54Ca27F81a9ad514B33B4",
  "0xA6f69bcB0b5573427478107a36414Bb068aE6555",
  "0x27a739aC5FF1e320bbbc0A22C15D72C00Ae5172f",
  "0x46b5412daff61f9978765d0bcd99d68bc3e7fb1d",
  "0x82741EC3D139B2423030Fa15F8861181acb0dFCd",
  "0xc599382b80f9F6B2F96b2D82A56eF7b1e7d2739b",
  "0x2B9Ea0Be51b77A46b75c78365AF9b5d21856f24B",
  "0x3796cDD33a94950a914c01D86242bF8E23A95cE6",
  "0x68A6276652743D3e2006026B40263285401CfD40",
  "0x7d27b7b65484110ff195FE2466A994395CA794bD",
  "0x5C353A476B65E8AB462a301B8471cC86fDF46B12",
  "0xb5437475b59AD4E7E2BBA1ACca979578Fe3f73Aa",
  "0x79dB0E96475DEDb5C7D7611C7633A8e1771365b2",
  "0x09BAF41ad555Bdf995d79B9C3Ce040920B4f31aA",
  "0xe66fF7f22733d44D3003A3D7a02116540588cD2d",
  "0x5b1ea878ee7d6514282d721a722a7bc9fb4f5072",
  "0x48c6f8Fc260809a2a19282FB60bfb3358701089e",
  "0x211C4B597bE2bcF184c47Df8a94F60832AFAA158",
  "0x66E62e8a33302270051584c0d6A2428Da5eA9743",
  "0xf8C8B6e39261727CC6EfDc88AeD3A1e900e9B648",
  "0xb1Ce6e0fE348a95D8e464e64FD5a006A6180Bb0e",
  "0xD886AB8DF75A48FB57943a28513BeA71c5235d2D",
  "0x1e82cdc70375fFf785B695B38976B83B79a30c2F",
  "0xc41E01c81606b8104255d09825d757e22c66DF47",
  "0x78DAb173a937806447C7F14a4889872Df75983FE",
  "0x36BE564317aC93334a11060EcC341f3Cf756E531",
  "0x7e52013Af4933a10664d25C71acb4bda693a2A56",
  "0x54efAb6726Ae5C96A9C5ca53d0d7aD8E86951342",
  "0xE50E7898A3B8ED4e613768E4d49eE04812Bf73e8",
  "0x940D6F7F596Aa65342fE490510b74282156C949B",
  "0xd8Eed6ac79f645863e1C9c49F4D9d4a2F9660051",
  "0x259722D36b2674C3CDaa8C3Dd757c1036559A9Fa",
  "0xe08Ff16cD9f923c7Da9aa0a1464438d11c280eba",
  "0xf0B2606a8801Fbc12C43D27509f6132Cf480F458",
  "0x76D1F440b808845B34Bddc6F98e0Ce66cc90b024",
  "0x59Dd48728eAc597662b421dBB9B0F78376De86ce",
  "0xBbFC680aA2580D54AF4F00fEB6f22e744B547bC6",
  "0x18Ccc3a396722C6955501325A1E5B8E97b0528e4",
  "0x01bab6c667c3cd3ddf1af1eea03f12094a4e5b47",
  "0xd12B21c74c9a831ba7B4E5e93d83CA1eB5FF3D40",
  "0x227206A1F2F6754f50EAECAAb99AbA655337819C",
  "0xC457Ac7De3170D476e74D9C9a1a116555138420f",
  "0xE5d3c70046a5B5B52b95DB2649b0635f8f4b3600",
  "0x98F83281aa0759c0b5446dacD86f2d25a4323DD5",
  "0xfB660c5863EB8671d828297794a0aa8d09c528Ea",
  "0x1179e21B2D596d29890aC35DCcc62484F0b515e9",
  "0x89D53e8612E8416bf960Dc9444ce0e2a0878a582",
  "0x8d1Ec12d43187cA38030AD3f052140c5f2859b27",
  "0x4d791C3Bef5050603454183AEA9b4FC94e073fC1",
  "0x44b48aDeC7e43268eA75cFDC0585410f87999b15",
  "0x7e36216e79e51feea4082b0abf5f0318c46ccc42",
  "0x62c7FFf230FDD9bE3f2360aeCFbe53ea26745802",
  // Newly added
  "0xe970A7155FA4fccE90998b34dBcE4741954717c7",
  "0x60D307A8726B8184843B87b2b4A0F0B36a6a05a0",
];
