import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAnbNlFQ5PpsakEUO6K_oVfWuVzEHAt-EU",
  authDomain: "dobermans-store.firebaseapp.com",
  projectId: "dobermans-store",
  storageBucket: "dobermans-store.appspot.com",
  messagingSenderId: "529613584373",
  appId: "1:529613584373:web:736be7dbdf9620f10952bc",
  measurementId: "G-WSDP30LP17",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);
