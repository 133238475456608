import React from "react";
import Skeleton from "react-loading-skeleton";

const AdminSkeletonTable = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="px-4 flex justify-between">
        <div className="flex gap-4">
          <Skeleton width={50} count={2} />

          <Skeleton width={150} count={2} />
        </div>

        <Skeleton width={150} count={2} />

        <Skeleton width={150} count={2} />

        <Skeleton width={150} count={2} />
      </div>

      <div className="px-4 flex justify-between">
        <div className="flex gap-4">
          <Skeleton width={50} count={2} />

          <Skeleton width={150} count={2} />
        </div>

        <Skeleton width={150} count={2} />

        <Skeleton width={150} count={2} />

        <Skeleton width={150} count={2} />
      </div>

      <div className="px-4 flex justify-between">
        <div className="flex gap-4">
          <Skeleton width={50} count={2} />

          <Skeleton width={150} count={2} />
        </div>

        <Skeleton width={150} count={2} />

        <Skeleton width={150} count={2} />

        <Skeleton width={150} count={2} />
      </div>
    </div>
  );
};

export default AdminSkeletonTable;
