import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as Dober } from "../../assets/dober.svg";
import { ReactComponent as Mans } from "../../assets/mans.svg";

// try  ease: [0, 0.71, 0.2, 1.01],

const underlineAnimation = {
  offScreen: { width: 0 },
  onScreen: {
    width: "100%",
    transition: {
      ease: "easeOut",
      duration: 0.2,
    },
  },
};

const animateUp = {
  onScreen: {
    y: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.8,
      //   delay: 1.3,
    },
  },
  offScreen: {
    y: -1000,
    transition: {
      ease: "easeInOut",
      duration: 0.8,
      delay: 0.3,
    },
  },
};

const animateDown = {
  onScreen: {
    y: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.8,
      //   delay: 1.3,
    },
  },
  offScreen: {
    y: 1000,
    transition: {
      ease: "easeInOut",
      duration: 0.8,
      delay: 0.3,
    },
  },
};

const TransitionAnimation = ({
  animateTrigger,
  setAnimateTrigger,
  setPageTransitioned,
}) => {
  const handleAnimationComplete = () => {
    setAnimateTrigger(false);
    setPageTransitioned(true);
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-screen z-50 ${
        !animateTrigger && "pointer-events-none"
      }`}
    >
      {/* <motion.div
        variants={underlineAnimation}
        initial="offScreen"
        animate="onScreen"
        className="h-[0.25px] mx-auto bg-gray-700"
      /> */}
      <motion.div
        variants={animateUp}
        initial="offScreen"
        animate={animateTrigger ? "onScreen" : "offScreen"}
        className="w-full h-[50%] bg-[#181818] border-b-[1px] border-gray-700 relative overflow-hidden"
        onAnimationComplete={handleAnimationComplete}
      >
        {/* <div className="w-full h-full opacity-[5%] bg-blackNoise" /> */}
        <motion.div
          initial={{ transform: "translateX(-10%) translateY(-10%)" }}
          animate={{
            transform: "translateX(10%) translateY(10%)",
          }}
          transition={{
            repeat: Infinity,
            duration: 0.2,
            ease: "linear",
            repeatType: "mirror",
          }}
          className="pointer-events-none absolute -inset-[100%] opacity-[5%] bg-blackNoise "
        />
        <Dober className="h-[100px] w-[325px] tab:h-[200px] tab:w-[600px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
      </motion.div>
      {/* <motion.div
          variants={underlineAnimation}
          initial="offScreen"
          animate="onScreen"
          className="h-[4px] mx-auto bg-black"
          onAnimationComplete={handleAnimationComplete}
        /> */}
      <motion.div
        variants={animateDown}
        initial="offScreen"
        animate={animateTrigger ? "onScreen" : "offScreen"}
        className="w-full h-[50%] bg-[#181818] border-t-[1px] border-gray-700 relative overflow-hidden"
      >
        <motion.div
          initial={{ transform: "translateX(-10%) translateY(-10%)" }}
          animate={{
            transform: "translateX(10%) translateY(10%)",
          }}
          transition={{
            repeat: Infinity,
            duration: 0.2,
            ease: "linear",
            repeatType: "mirror",
          }}
          className="pointer-events-none absolute -inset-[100%] opacity-[5%] bg-blackNoise "
        />
        <Mans className="h-[100px] w-[300px] tab:h-[200px] tab:w-[500px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
      </motion.div>
      {/* <motion.div
          variants={underlineAnimation}
          initial="offScreen"
          animate="onScreen"
          className="h-[4px] mx-auto bg-black"
        /> */}
    </div>
  );
};

export default TransitionAnimation;
