import {
  Card,
  // Typography,
  List,
  ListItem,
  ListItemPrefix,
  // ListItemSuffix,
  // Chip,
} from "@material-tailwind/react";
import {
  // PresentationChartBarIcon,
  // ShoppingBagIcon,
  // UserCircleIcon,
  // Cog6ToothIcon,
  InboxIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";
import { useDisconnect } from "wagmi";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";

export function AdminSidebar() {
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();

  const handleDisconnect = () => {
    disconnect();
    navigate("/");
  };
  return (
    <Card className="sticky top-0 left-0  h-screen w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 bg-[#1e2025]">
      <div className="my-3 p-4 ">
        {/* <Typography variant="h5" color="white">
          Admin Panel
        </Typography> */}
        <img src={logo} alt="logo" className="w-[150px]" />
      </div>
      <List>
        {/* ==================== ALL ORDERS ==================== */}
        <ListItem className="text-white font-VitoReg">
          <ListItemPrefix>
            <InboxIcon className="h-5 w-5" />
          </ListItemPrefix>
          Store orders
          {/* <ListItemSuffix>
            <Chip
              value="14"
              size="sm"
              variant="ghost"
              color="blue-gray"
              className="rounded-full bg-gray-400"
            />
          </ListItemSuffix> */}
        </ListItem>

        <ListItem
          onClick={handleDisconnect}
          className="text-gray-400 font-VitoReg"
        >
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5" />
          </ListItemPrefix>
          Log Out
        </ListItem>
      </List>
    </Card>
  );
}
