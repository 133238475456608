import React from "react";
import Logo from "../../assets/logo.svg";
import MobileLogo from "../../assets/logoMobile.png";
import { Link as LinkS } from "react-scroll";
import { useAccount } from "wagmi";

const Header = ({ pageTransitioned, setOpen }) => {
  const { isConnected, address } = useAccount();

  return (
    <div className="px-6 py-6 lap:px-10 lap:py-10 flex  justify-between items-center font-IBMPlex font-bold  tab:text-lg">
      <img src={Logo} alt="logo" className="w-[150px] lap:block hidden" />
      <img src={MobileLogo} alt="logo" className="w-[50px] lap:hidden" />

      {pageTransitioned && isConnected && (
        <div className="space-x-10">
          <LinkS
            to="claim"
            smooth={true}
            duration={1250}
            spy={true}
            exact="true"
          >
            <span className="text-[#FF0033] hover:text-white transition-all duration-300 cursor-pointer">
              Claim
            </span>
          </LinkS>

          <button
            onClick={() => setOpen(true)}
            className="hover:text-white text-[#FF0033] transition-all duration-300 "
          >
            Track order
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
