import React from "react";
import { motion } from "framer-motion";
import Header from "../Header/Header";
// import glitchVid from "../../assets/video/glitch.mp4";
import { CustomConnectWallet } from "../CustomConnectWallet/CustomConnectWallet";
import { Button } from "../Button/Button";
import { useAccount } from "wagmi";
import { shortenAddress } from "../../utils/helpers/ShortenAddress";
import { useAccountModal } from "@rainbow-me/rainbowkit";
import The88 from "../../assets/the88.png";
import DBR from "../../assets/DBR.png";
import MNS from "../../assets/MNS.png";
import Abstract from "../../assets/Abstract.png";
import { Link } from "react-scroll";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";

const fadeIn = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
      delay: 0.2,
    },
  },
};

const Landing = ({ isFamily, pageTransitioned, setOpen }) => {
  const { isConnected, address } = useAccount();
  const { openAccountModal } = useAccountModal();

  return (
    //TODO review parent background color
    <div className="relative overflow-hidden bg-black  h-screen">
      <motion.div
        initial={{ transform: "translateX(-10%) translateY(-10%)" }}
        animate={{
          transform: "translateX(10%) translateY(10%)",
        }}
        transition={{
          repeat: Infinity,
          duration: 0.2,
          ease: "linear",
          repeatType: "mirror",
        }}
        className="pointer-events-none absolute -inset-[100%] opacity-[5%] bg-blackNoise "
      />

      <Header pageTransitioned={pageTransitioned} setOpen={setOpen} />

      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className=" text-[160px] flex   justify-center items-center font-VitoBold">
          {isConnected && !isFamily ? (
            <motion.div
              variants={fadeIn}
              initial="offScreen"
              animate="onScreen"
            >
              <div class="hero-container text-[70px] tab:text-[100px]">
                <h2
                  class="hero2 glitch layers"
                  data-text="NOT PART OF THE FAMILY"
                >
                  <span>NOT PART OF THE FAMILY</span>
                </h2>
              </div>
            </motion.div>
          ) : isConnected && isFamily && pageTransitioned ? (
            <div>
              <div className="pb-10 flex flex-col lap:flex-row justify-center items-center gap-10">
                <img
                  src={DBR}
                  alt="logo"
                  className="h-[50px] lap:h-[80px] hidden mm:block"
                />
                <img
                  src={Abstract}
                  alt="logo"
                  className="h-[300px] lap:h-[400px]"
                />
                <img
                  src={MNS}
                  alt="logo"
                  className="h-[50px] lap:h-[80px] hidden mm:block"
                />
              </div>
            </div>
          ) : (
            <div class="hero-container">
              <h2 class="hero glitch layers" data-text="THE 88">
                <span>THE 88</span>
              </h2>
            </div>
            // <img src={The88} alt="logo" className="w-[700px]" />
          )}
        </div>

        <div className="flex tab:flex-row flex-col items-center justify-center  tab:gap-10">
          {isConnected ? (
            <Button onClick={openAccountModal} buttonType="fiBtn">
              <span className="btn-fi-line"></span>
              <span> {shortenAddress(address)}</span>
            </Button>
          ) : (
            <CustomConnectWallet />
          )}
          <a href="https://dobermans.io/">
            <Button href="www.twitter.com" buttonType="fiBtn">
              Main Website
            </Button>
          </a>
        </div>
      </div>

      {/* TODO: remove frm homepage */}
      {isConnected && isFamily && pageTransitioned && (
        <div className="w-full justify-center hidden tab:flex">
          <Link
            to="claim"
            smooth={true}
            duration={1250}
            spy={true}
            exact="true"
            className="absolute bottom-6 border-[1px] border-[#FF0033] flex justify-center items-center z-10 rounded-full h-12 w-12 cursor-pointer bg-[#FF0033] bg-opacity-10 hover:bg-[#FF0033] hover:bg-opacity-20 shadow-[0_0_10px_rgba(255,0,51,1)] hover:shadow-[0_0_22px_rgba(255,0,51,1)] duration-[200ms] animate-bounce"
          >
            <Arrow className="h-10 w-10 mt-[26px] ml-[13px]" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default Landing;
