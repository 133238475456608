import React, { useState, useEffect } from "react";
import { Button } from "../Button/Button";
import FormField from "../FormField/FormField";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  //   updateDoc,
  //   doc,
  //   deleteDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase/FirebaseConfig";
import { NotifyErrorDark, NotifySuccessDark } from "../../utils/helpers/Notify";
import { useAccount } from "wagmi";
import { ReactComponent as twitter } from "../../assets/twitter.svg";
import { ReactComponent as instagram } from "../../assets/instagram.svg";
import Skeleton from "react-loading-skeleton";
// import { ReactComponent as discord } from "../../assets/discord.svg";
import { SELECT_COUNTRIES } from "../../utils/helpers/Countries";

// const SELECT_COUNTRIES = ["United States", "Canada", "Mexico"];

const SOCIALS = [
  {
    id: 1,
    label: "Twitter",
    icon: twitter,
    link: "https://twitter.com/Dobermans_io",
  },
  {
    id: 2,
    label: "Instagram",
    icon: instagram,
    link: "https://www.instagram.com/dobermans_io",
  },
  //   { id: 3, label: "Discord", icon: discord, link: "https://discord.com" },
];

const ClaimPhases = ({ setClaimPhase, claimPhase }) => {
  const [loaded, setLoaded] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    stateOrProvince: "",
    streetAddress: "",
    city: "",
    ZipOrPostalCode: "",
  });

  const ordersCollectionRef = collection(db, "orders");

  const { isConnected, address } = useAccount();

  useEffect(() => {
    setLoaded(false);

    const checkForOrder = async () => {
      // TODO This is the old way of querying the database

      // try {
      //   const data = await getDocs(ordersCollectionRef);

      //   const orders = data.docs.map((doc) => doc.data().walletAddress);

      //   if (address && orders.includes(address)) {
      //     setClaimPhase(3);
      //     setLoaded(true);
      //   } else if (address) {
      //     setClaimPhase(1);
      //     setLoaded(true);
      //   }
      // } catch (e) {
      //   console.log(e);
      // }

      if (isConnected && address) {
        try {
          const q = query(
            collection(db, "orders"),
            where("walletAddress", "==", address)
          );
          const querySnapshot = await getDocs(q);

          // console.log(!querySnapshot.empty);

          if (!querySnapshot.empty) {
            setClaimPhase(3);
            setLoaded(true);
          } else if (address) {
            setClaimPhase(1);
            setLoaded(true);
          }
        } catch (error) {
          console.error("Error checking for order:", error);
        }
      }
    };

    checkForOrder();
  }, [address]);

  useEffect(() => {
    if (
      form.firstName &&
      form.lastName &&
      form.email &&
      form.country &&
      form.stateOrProvince &&
      form.streetAddress &&
      form.city &&
      form.ZipOrPostalCode
    ) {
      setAllFieldsValid(true);
    } else {
      setAllFieldsValid(false);
    }
  }, [form]);

  // console.log(form);

  const handleFormFieldChange = (fieldName, e) => {
    setForm({ ...form, [fieldName]: e.target.value });
  };

  const handleSelectChange = (e) => {
    setForm({ ...form, country: e.target.value });
  };

  const handleOrderSubmission = async () => {
    setBtnLoading(true);
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);

    try {
      await addDoc(ordersCollectionRef, {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        country: form.country,
        state: form.stateOrProvince,
        address: form.streetAddress,
        city: form.city,
        zip: form.ZipOrPostalCode,
        timestamp: currentTimestamp,
        orderStatus: "processing",
        walletAddress: address,
        trackingNumber: "",
      });

      NotifySuccessDark("Order successfully submitted");
      setClaimPhase(3);
      setBtnLoading(false);
    } catch (e) {
      NotifyErrorDark("Something went wrong");
      console.log(e);
      setBtnLoading(false);
    }
  };

  return (
    <div id="claim" className="w-full lap:w-[50%]">
      {!loaded ? (
        <div className="min-h-screen px-10 py-14 font-IBMPlex text-black flex flex-col justify-between">
          <div className="space-y-14">
            <Skeleton
              baseColor="#A2A2A2"
              highlightColor="#BABABA"
              height={48}
              width={250}
            />

            <div className="flex flex-col gap-10 ">
              <Skeleton
                baseColor="#A2A2A2"
                highlightColor="#BABABA"
                count={5}
              />

              <Skeleton
                baseColor="#A2A2A2"
                highlightColor="#BABABA"
                count={3}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <Skeleton
              baseColor="#A2A2A2"
              highlightColor="#BABABA"
              height={50}
              width={200}
            />
          </div>
        </div>
      ) : claimPhase === 1 ? (
        <div className="min-h-screen px-6 lap:px-10 py-10 lap:py-14 font-IBMPlex text-black flex flex-col justify-between">
          <div className="space-y-10 lap:space-y-14">
            <div className="font-bold  text-4xl lap:text-5xl  flex gap-4">
              <span className="text-base pt-1">01</span>
              <span className="text-gray-400 ">/</span>
              <h2>CLAIM.</h2>
            </div>

            <div className="space-y-6 lap:space-y-10  lap:text-2xl max-w-2xl font-medium">
              <p>The Dobefather remembers.</p>
              <p>A small gift for your loyalty awaits.</p>
            </div>
          </div>

          <div className="flex justify-end">
            <Button onClick={() => setClaimPhase(2)} buttonType="claimSection">
              GO TO SHIPPING
            </Button>
          </div>
        </div>
      ) : claimPhase === 2 ? (
        <div className="min-h-screen px-6 lap:px-10 py-10 lap:py-14 font-IBMPlex text-black flex flex-col justify-between">
          <div className="space-y-10 lap:space-y-14">
            <div className="font-bold text-4xl lap:text-5xl  flex gap-4">
              <span className="text-base pt-1">02</span>
              <span className="text-gray-400 ">//</span>
              <h2>SHIPPING.</h2>
            </div>

            <div className="lap:text-2xl max-w-2xl font-medium">
              <p>
                Please enter your shipping details to receive your Dobermans
                package.
              </p>
            </div>

            <div className="flex flex-col gap-6">
              <div className="flex items-center justify-between gap-4 lap:gap-10">
                <FormField
                  label="First name"
                  classType="main"
                  inputType="text"
                  inputId="first-name"
                  autoId="given-name"
                  value={form.firstName}
                  onChange={(e) => handleFormFieldChange("firstName", e)}
                  disabled={btnLoading}
                />
                <FormField
                  label="Last name"
                  classType="main"
                  inputType="text"
                  inputId="last-name"
                  autoId="family-name"
                  value={form.lastName}
                  onChange={(e) => handleFormFieldChange("lastName", e)}
                  disabled={btnLoading}
                />
              </div>

              <FormField
                label="Email address"
                classType="main"
                inputType="email"
                inputId="email"
                autoId="email"
                value={form.email}
                onChange={(e) => handleFormFieldChange("email", e)}
                disabled={btnLoading}
              />

              <div className="flex items-center justify-between gap-4 lap:gap-10">
                <div className="w-full">
                  <div class="sm:col-span-3">
                    <label class="block text-sm font-medium leading-6 text-gray-900">
                      Country
                    </label>
                    <div class="mt-2">
                      <select
                        autoComplete="country-name"
                        id="country"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#FF0033]  sm:text-sm sm:leading-6"
                        onChange={handleSelectChange}
                        disabled={btnLoading}
                      >
                        <option value="" disabled selected>
                          Select Country
                        </option>
                        {SELECT_COUNTRIES.map((country) => (
                          <option key={country}>{country}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <FormField
                  label="State / Province"
                  classType="main"
                  inputType="text"
                  inputId="region"
                  autoId="address-level1"
                  value={form.stateOrProvince}
                  onChange={(e) => handleFormFieldChange("stateOrProvince", e)}
                  disabled={btnLoading}
                />
              </div>

              <FormField
                label="Street address"
                classType="main"
                inputType="text"
                inputId="street-address"
                autoId="street-address"
                value={form.streetAddress}
                onChange={(e) => handleFormFieldChange("streetAddress", e)}
                disabled={btnLoading}
              />

              <div className="flex items-center justify-between gap-4 lap:gap-10">
                <FormField
                  label="City"
                  classType="main"
                  inputType="text"
                  inputId="city"
                  autoId="address-level2"
                  value={form.city}
                  onChange={(e) => handleFormFieldChange("city", e)}
                  disabled={btnLoading}
                />
                <FormField
                  label="ZIP / Postal code"
                  classType="main"
                  inputType="text"
                  inputId="postal-code"
                  autoId="postal-code"
                  value={form.ZipOrPostalCode}
                  onChange={(e) => handleFormFieldChange("ZipOrPostalCode", e)}
                  disabled={btnLoading}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between pt-10">
            <Button
              disabled={btnLoading}
              onClick={() => setClaimPhase(1)}
              buttonType={
                btnLoading ? "claimSectionBackDisabled" : "claimSectionBack"
              }
            >
              BACK
            </Button>
            <Button
              disabled={btnLoading || !allFieldsValid}
              onClick={handleOrderSubmission}
              buttonType={
                btnLoading || !allFieldsValid
                  ? "claimSectionDisabled"
                  : "claimSection"
              }
            >
              {btnLoading ? "CLAIMING..." : "CLAIM"}
            </Button>
          </div>
        </div>
      ) : (
        <div className="min-h-screen px-6 lap:px-10 py-10 lap:py-14 font-IBMPlex text-black flex flex-col justify-between">
          <div className="space-y-10 lap:space-y-14">
            <div className="font-bold text-4xl lap:text-5xl flex gap-4">
              <span className="text-base pt-1">03</span>
              <span className="text-gray-400 ">///</span>
              <h2>SUCCESS.</h2>
            </div>

            <div className="space-y-6 lap:space-y-10  lap:text-2xl max-w-2xl font-medium">
              <p>A package from the Dobermans to you is promised.</p>
              <p>The streets of Dobercity whisper of a storm approaching.</p>
              <p>Disruption is imminent.</p>
              <p>Doors will open.</p>

              <div className="flex items-center gap-4">
                {SOCIALS.map(({ id, link, icon }) => (
                  <a
                    key={id}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {React.createElement(icon, {
                      className:
                        "w-16 h-16 text-[#FF0033] transition-all duration-300",
                    })}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClaimPhases;
