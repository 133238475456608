import React, { useState, useEffect } from "react";
import { AdminSidebar } from "../../components/AdminSidebar/AdminSidebar";
import AdminOrderCard from "../../components/AdminOrderCard/AdminOrderCard";
import { InboxIcon } from "@heroicons/react/24/solid";
import { TruckIcon, CogIcon } from "@heroicons/react/24/outline";
import Skeleton from "react-loading-skeleton";
import { CustomConnectWallet } from "../../components/CustomConnectWallet/CustomConnectWallet";
import { useNavigate } from "react-router-dom";

// import { AdminTable } from "../../components/AdminTable/AdminTable";
import AdminManageOrder from "../../components/AdminManageOrder/AdminManageOrder";

import { db } from "../../utils/firebase/FirebaseConfig";
import {
  collection,
  getDocs,
  //   addDoc,
  //   updateDoc,
  //   doc,
  //   deleteDoc,
} from "firebase/firestore";
import AdminSkeletonTable from "../../components/AdminSkeletonTable/AdminSkeletonTable";

import { useAccount } from "wagmi";
import logo from "../../assets/logo.svg";
import { ReactComponent as Refresh } from "../../assets/refresh.svg";
// import { Tabs, TabsHeader, Tab, Button } from "@material-tailwind/react";

const manageTabs = [
  { label: 1, value: "All orders", icon: InboxIcon },
  { label: 2, value: "Processing orders", icon: CogIcon },
  { label: 3, value: "Shipped orders", icon: TruckIcon },
];

const tableHead = [
  { label: 1, title: "Wallet address" },
  { label: 2, title: "Order placed" },
  { label: 3, title: "Order status" },
  { label: 4, title: "Manage order" },
];

const orderStats = [
  { label: 1, title: "Total number of orders" },
  { label: 2, title: "Total processing orders" },
  { label: 3, title: "Total shipped orders" },
];

const Admin = () => {
  const [loaded, setLoaded] = useState(false);
  const [tab, setTab] = useState(1);
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [processingOrders, setProcessingOrders] = useState([]);
  const [shippedOrders, setShippedOrders] = useState([]);
  const [orderToManage, setOrderToManage] = useState([]);

  const { isConnected, address } = useAccount();
  const navigate = useNavigate();

  // const adminAddress = "0x98B922021bB2aa87697E12030d60a1cc8b57c975";
  // const adminAddress = "0x7667A06a7b0c7E3185da4FA4e452A9d1cd24D9dB";

  const adminArray = [
    "0x98B922021bB2aa87697E12030d60a1cc8b57c975",
    "0x7667A06a7b0c7E3185da4FA4e452A9d1cd24D9dB",
    "0xD89522b2C23137C356FbF9090341Fd74432FA4A2",
  ];

  const ordersCollectionRef = collection(db, "orders");

  const goHome = () => {
    navigate("/");
  };

  useEffect(() => {
    //
    if (page === 1 && isConnected && adminArray.includes(address)) {
      setLoaded(false);
      const getOrdersData = async () => {
        const data = await getDocs(ordersCollectionRef);

        const orders = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        setOrders(orders.sort((a, b) => b.timestamp - a.timestamp));

        setShippedOrders(
          data.docs
            .filter((doc) => doc.data().orderStatus === "shipped")
            .map((doc) => ({ ...doc.data(), id: doc.id }))
        );

        setProcessingOrders(
          data.docs
            .filter((doc) => doc.data().orderStatus === "processing")
            .map((doc) => ({ ...doc.data(), id: doc.id }))
        );

        // console.log(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setLoaded(true);
      };

      getOrdersData();
    }
  }, [page, isConnected, address]);

  return (
    <div className="bg-[#0f1214]">
      {isConnected && adminArray.includes(address) ? (
        <div className=" flex">
          <AdminSidebar />

          {/* CONTENT AREA */}
          {page === 1 ? (
            <div className="text-white  w-full px-20 py-10">
              <span className="text-3xl font-VitoBold ">Shop orders</span>

              <div className="flex items-center gap-20">
                {orderStats.map(({ label, title }) => (
                  <div key={label} className="font-VitoMed text-gray-500 mt-20">
                    <h2>{title}</h2>
                    {loaded ? (
                      <p className="text-white font-bold text-5xl mt-4">
                        {label === 1
                          ? orders.length
                          : label === 2
                          ? processingOrders.length
                          : shippedOrders.length}
                      </p>
                    ) : (
                      <div className="mt-4">
                        <Skeleton height={52} />
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className=" w-full rounded-xl bg-[#1e2025] mt-10 px-4 py-4">
                <div className="flex justify-between">
                  <div className="mb-20 mt-2 flex gap-3 rounded-full  border-[0.5px] border-gray-700 py-[3px] px-[3px] w-[550px]">
                    {manageTabs.map(({ label, value, icon }) => (
                      <div
                        key={label}
                        className={`py-2 px-4 font-VitoReg rounded-full cursor-pointer flex items-center gap-2 transition-all duration-300 ${
                          tab === label
                            ? "text-white  bg-gray-500 bg-opacity-20"
                            : "text-gray-400  hover:bg-gray-500 hover:bg-opacity-20 hover:text-white"
                        }`}
                      >
                        {React.createElement(icon, { className: "w-6 h-6" })}
                        <div key={label} onClick={() => setTab(label)}>
                          {value}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* <Refresh className="h-8 w-8" /> */}
                </div>

                <div
                  className={`flex text-gray-500 font-VitoReg pb-3 ${
                    loaded && "border-b-[0.5px] border-gray-700"
                  }`}
                >
                  {tableHead.map(({ label, title }) => (
                    <div
                      key={label}
                      className={`w-[25%] flex px-3 ${
                        label === 1
                          ? "justify-start"
                          : label === 4
                          ? "justify-end"
                          : "justify-center"
                      }`}
                    >
                      {title}
                    </div>
                  ))}
                </div>

                {loaded && tab === 1 ? (
                  <div>
                    {orders.map((order) => (
                      <AdminOrderCard
                        key={order.id}
                        order={order}
                        setOrderToManage={setOrderToManage}
                        setPage={setPage}
                      />
                    ))}
                  </div>
                ) : loaded && tab === 2 ? (
                  <div>
                    {orders
                      .filter((order) => order.orderStatus === "processing")
                      .map((order) => (
                        <AdminOrderCard
                          key={order.id}
                          order={order}
                          setOrderToManage={setOrderToManage}
                          setPage={setPage}
                        />
                      ))}
                  </div>
                ) : loaded && tab === 3 ? (
                  <div>
                    {orders
                      .filter((order) => order.orderStatus === "shipped")
                      .map((order) => (
                        <AdminOrderCard
                          key={order.id}
                          order={order}
                          setOrderToManage={setOrderToManage}
                          setPage={setPage}
                        />
                      ))}
                  </div>
                ) : (
                  <AdminSkeletonTable />
                )}

                {loaded && tab === 1 && orders.length < 1 ? (
                  <div className="py-20 flex font-VitoReg text-[#FF0033] justify-center items-center">
                    No orders to display
                  </div>
                ) : loaded && tab === 2 && processingOrders.length < 1 ? (
                  <div className="py-20 flex font-VitoReg text-[#FF0033] justify-center items-center">
                    No processing orders to display
                  </div>
                ) : loaded && tab === 3 && shippedOrders.length < 1 ? (
                  <div className="py-20 flex font-VitoReg text-[#FF0033] justify-center items-center">
                    No shipped orders to display
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <AdminManageOrder
              orderToManage={orderToManage}
              setPage={setPage}
              setOrderToManage={setOrderToManage}
            />
          )}
        </div>
      ) : isConnected && !adminArray.includes(address) ? (
        <div className="h-screen flex justify-center items-center">
          <img
            src={logo}
            alt="logo"
            className="w-[150px] fixed top-10 left-10 cursor-pointer"
            onClick={goHome}
          />
          <div className="flex flex-col gap-6 justify-center items-center text-[#FF0033]">
            <span className="font-VitoBold text-6xl">ACCESS DENIED</span>
            <span className="font-VitoLight  text-2xl">
              Unauthorized access: User is not an admin
            </span>
          </div>
        </div>
      ) : (
        <div className="h-screen flex justify-center items-center">
          <img
            src={logo}
            alt="logo"
            className="w-[150px] fixed top-10 left-10 cursor-pointer"
            onClick={goHome}
          />
          <CustomConnectWallet />
        </div>
      )}
    </div>
  );
};

export default Admin;
