import React, { useState } from "react";
import { calculateTimeElapsed } from "../../utils/helpers/TimeElapsed";
import {
  Chip,
  IconButton,
  Option,
  Select,
  Tooltip,
  Input,
  Button,
} from "@material-tailwind/react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { db } from "../../utils/firebase/FirebaseConfig";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { NotifyError, NotifySuccess } from "../../utils/helpers/Notify";
import AdminDeleteBtn from "../AdminDeleteBtn/AdminDeleteBtn";

const AdminManageOrder = ({ orderToManage, setPage, setOrderToManage }) => {
  const [status, setStatus] = useState("");
  const [trackingNo, setTrackingNo] = useState("");
  const [hatNo, setHatNo] = useState("");
  const [editStatus, setEditStatus] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTrackingInput = (e) => {
    const newVal = e.target.value;
    setTrackingNo(newVal);
  };

  const handleHatInput = (e) => {
    const newVal = e.target.value;
    setHatNo(newVal);
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  // console.log();

  const updateOrderStatus = async (id, newStatus, newTracking) => {
    setBtnLoading(true);
    try {
      const userDoc = doc(db, "orders", id);
      const newFields = {
        orderStatus: newStatus,
        trackingNumber: newTracking,
        hatNumber: hatNo,
      };

      await updateDoc(userDoc, newFields);

      setBtnLoading(false);
      setEditStatus(false);
      setStatus("");
      setTrackingNo("");
      setOrderToManage({
        ...orderToManage,
        orderStatus: newStatus,
        trackingNumber: newTracking,
        hatNumber: hatNo,
      });
      NotifySuccess("Order status updated");
    } catch (e) {
      console.log(e);
      setBtnLoading(false);
      NotifyError("Something went wrong");
    }
  };

  const deleteOrder = async (id) => {
    setOpen(false);
    setDeleteBtnLoading(true);
    try {
      const userDoc = doc(db, "orders", id);
      await deleteDoc(userDoc);
      setPage(1);
      setDeleteBtnLoading(false);
      NotifySuccess("Order deleted");
    } catch (e) {
      setDeleteBtnLoading(false);
      NotifyError("Something went wrong");
      console.log(e);
    }
  };

  const {
    walletAddress,
    timestamp,
    orderStatus,
    firstName,
    lastName,
    address,
    city,
    country,
    email,
    id,
    state,
    trackingNumber,
    zip,
    hatNumber,
  } = orderToManage;

  return (
    <div className="text-white  w-full px-20 py-10 font-VitoMed">
      <span className="text-3xl font-VitoBold">Manage order</span>

      <div className=" w-full rounded-xl bg-[#1e2025] mt-10 px-10 py-4 ">
        <div className="font-VitoBold text-2xl text-[#FF0033]">
          User details
        </div>
        <div className="  text-xl flex items-center gap-4 mt-6">
          <h2 className="text-gray-500 font-VitoLight">Wallet address:</h2>{" "}
          <span className=" ">{walletAddress}</span>
        </div>
        <div className="text-xl flex flex-wrap justify-start gap-8 mt-6">
          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">First name :</h2>
            <span>{firstName}</span>
          </div>

          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">Last name :</h2>
            <span>{lastName}</span>
          </div>

          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">Email:</h2>
            <span>{email}</span>
          </div>
        </div>

        <div className="font-bold text-2xl mt-10 text-[#FF0033]">
          Shipping details
        </div>
        <div className=" mt-6 flex flex-wrap justify-start gap-8 text-xl">
          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">Country :</h2>
            <span>{country}</span>
          </div>

          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">State/Province :</h2>
            <span>{state}</span>
          </div>

          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">City :</h2>
            <span>{city}</span>
          </div>

          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">Address :</h2>
            <span>{address}</span>
          </div>

          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">ZIP/Postal code :</h2>
            <span>{zip}</span>
          </div>

          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">Order placed :</h2>
            <span>{calculateTimeElapsed(timestamp)}</span>
          </div>
        </div>

        <div className="font-bold flex items-center gap-4  text-2xl mt-20 mb-6">
          <h2 className="text-[#FF0033]">Order status</h2>
          <div className="group" onClick={() => setEditStatus(true)}>
            <Tooltip content="Edit status Data">
              <IconButton
                variant="text"
                className="group-hover:bg-gray-400 group-hover:bg-opacity-20 transition-all duration-100"
              >
                <PencilIcon className="h-4 w-4 text-white" />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className="flex flex-wrap justify-start gap-8 text-xl">
          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">Status :</h2>
            {orderStatus === "shipped" ? (
              <Chip
                size="sm"
                variant="ghost"
                value={"shipped"}
                color={"green"}
                className="text-green-300"
              />
            ) : (
              <Chip
                size="sm"
                variant="ghost"
                value={"processing"}
                color={"yellow"}
                className="text-yellow-300"
              />
            )}
          </div>

          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">Tracking no :</h2>
            <span>{trackingNumber || "N/A"}</span>
          </div>

          <div className="flex items-center gap-4">
            <h2 className="text-gray-500 font-VitoLight">Hat no :</h2>
            <span>{hatNumber || "N/A"}</span>
          </div>
        </div>

        {/* peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed transition-all border text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 
        

        peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-gray-900 border-t-transparent
        */}

        {editStatus && (
          <div className=" mt-10 flex flex-col gap-10">
            <div className="w-72">
              <Select label="Select Status" className="text-blue-gray-200">
                <Option
                  onClick={() => {
                    setStatus("processing");
                    setTrackingNo("");
                    setHatNo("");
                  }}
                >
                  processing
                </Option>
                <Option onClick={() => setStatus("shipped")}>shipped</Option>
              </Select>
            </div>

            {status === "shipped" && (
              <div className="flex items-center gap-4">
                <div className="flex flex-col ">
                  <span className="text-[#808191] text-[14px] font-epilogue font-medium leading-[22px] mb-[10px]">
                    Tracking no:
                  </span>
                  <input
                    spellCheck="false"
                    placeholder="Enter tracking #"
                    value={trackingNo}
                    label="Tracking Number"
                    className=" outline-none border-[1px] border-[#3a3a43] bg-transparent font-epilogue text-white placeholder:text-[#5f687d] rounded-[10px] w-[300px]"
                    color="white"
                    onChange={handleTrackingInput}
                  />
                </div>

                <div className="flex flex-col ">
                  <span className="text-[#808191] text-[14px] font-epilogue font-medium leading-[22px] mb-[10px]">
                    Hat no:
                  </span>
                  <input
                    spellCheck="false"
                    type="number"
                    placeholder="Enter hat #"
                    value={hatNo}
                    label="Tracking Number"
                    className=" outline-none border-[1px] border-[#3a3a43] bg-transparent font-epilogue text-white placeholder:text-[#5f687d] rounded-[10px] w-[200px]"
                    color="white"
                    onChange={handleHatInput}
                    onKeyDown={blockInvalidChar}
                  />
                </div>
              </div>
            )}

            <div className="flex items-center gap-4">
              <Button
                onClick={() => {
                  setEditStatus(false);
                  setStatus("");
                  setTrackingNo("");
                  setHatNo("");
                }}
                variant="filled"
                className="bg-black font-VitoBold"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  !status ||
                  (status === "shipped" && (!trackingNo || !hatNo)) ||
                  btnLoading
                }
                variant="filled"
                className="bg-white text-black font-VitoBold"
                onClick={() => updateOrderStatus(id, status, trackingNo, hatNo)}
              >
                {btnLoading ? "Saving..." : "Save"}
              </Button>
            </div>
          </div>
        )}

        <div className="mt-20 flex justify-between">
          <Button
            onClick={() => {
              setEditStatus(false);
              setStatus("");
              setTrackingNo("");
              setPage(1);
            }}
            variant="filled"
            className="bg-black font-VitoBold"
          >
            Back
          </Button>

          <Button
            onClick={() => setOpen(true)}
            variant="filled"
            className="bg-red-600 font-VitoBold"
            disabled={deleteBtnLoading}
          >
            {deleteBtnLoading ? "deleting..." : "delete order"}
          </Button>
        </div>
      </div>
      <AdminDeleteBtn
        open={open}
        setOpen={setOpen}
        orderId={id}
        deleteOrder={deleteOrder}
      />
    </div>
  );
};

export default AdminManageOrder;
