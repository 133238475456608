import React from "react";
import Marquee from "react-fast-marquee";
import { ReactComponent as twitter } from "../../assets/twitter.svg";
import { ReactComponent as instagram } from "../../assets/instagram.svg";
import { ReactComponent as discord } from "../../assets/discord.svg";

const SOCIALS = [
  {
    id: 1,
    label: "Twitter",
    icon: twitter,
    link: "https://twitter.com/Dobermans_io",
  },
  {
    id: 2,
    label: "Instagram",
    icon: instagram,
    link: "https://www.instagram.com/dobermans_io",
  },
  //   { id: 3, label: "Discord", icon: discord, link: "https://discord.com" },
];

const Footer = ({ setOpen }) => {
  return (
    <div className="h-[425px] bg-black font-IBMPlex font-semibold ">
      <div className="flex h-[350px]">
        <div className="w-full lap:w-[50%]">
          <div
            onClick={() => setOpen(true)}
            className=" h-[175px] flex flex-col gap-6 justify-center px-10 border-t-[0.25px] border-gray-800 group text-white transition-all duration-300 cursor-pointer bg-gray-100 bg-opacity-0 hover:bg-opacity-[5%]"
          >
            <span>// TRACK YOUR ORDER</span>
            <span className="text-gray-500 group-hover:text-white transition-all duration-300">
              CLICK HERE TO TRACK YOUR ORDER
            </span>
          </div>
          <div className=" h-[175px] flex flex-col gap-4 justify-center px-10 border-t-[0.25px] border-gray-800">
            <span>// OUR SOCIALS</span>
            <div className="flex gap-10 pl-4">
              {SOCIALS.map(({ id, link, icon }) => (
                <a
                  key={id}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {React.createElement(icon, {
                    className:
                      "w-9 h-9 text-red-500 hover:text-white transition-all duration-300",
                  })}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="w-[50%] bg-red-500  flex-col justify-center lap:flex hidden">
          <Marquee
            className="font-VitoBold text-white text-[200px] h-[180px] pt-[72px] flex items-center gap-10 leading-none overflow-y-hidden"
            gradient={false}
            speed={100}
          >
            <span className="pr-10">- DOBERMANS</span>

            <span className="pr-10">- DOBERMANS</span>
          </Marquee>
          <Marquee
            className="font-VitoBold text-gray-800 text-[100px] flex items-center "
            gradient={false}
            speed={60}
          >
            <span className="px-4">- THE 88</span>

            <span className="px-4">- THE 88</span>

            <span className="px-4">- THE 88</span>

            <span className="px-4">- THE 88</span>

            <span className="px-4">- THE 88</span>
          </Marquee>
        </div>
      </div>
      <div className="h-[75px] flex items-center justify-between px-4 lap:px-10 border-t-[0.25px] border-gray-800  text-[12px] lap:text-base">
        <span className="hidden lap:block">©COPYRIGHT</span>
        <span className="lap:hidden">©DOBERMANS 2024</span>
        <span className="hidden lap:block">DOBERMANS 2024</span>
        <span>ALL RIGHTS RESERVED</span>
      </div>
    </div>
  );
};

export default Footer;
