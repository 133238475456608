import { Route, Routes } from "react-router-dom";
import Admin from "./routes/admin/Admin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Home from "./routes/home/Home";

function App() {
  return (
    <div className="h-screen ">
      <SkeletonTheme baseColor="#313131" highlightColor="#525252">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
        <ToastContainer />
      </SkeletonTheme>
    </div>
  );
}

export default App;
