import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { TruckIcon, CogIcon } from "@heroicons/react/24/outline";
import Skeleton from "react-loading-skeleton";
import {
  getDocs,
  query,
  where,
  collection,
  getDoc,
  // orderBy,
} from "firebase/firestore";
import { db } from "../../utils/firebase/FirebaseConfig";
import { useAccount } from "wagmi";
import { shortenAddress } from "../../utils/helpers/ShortenAddress";

export default function TrackOrderModal({ open, setOpen }) {
  const [loaded, setLoaded] = useState(false);
  const [orderPresent, setOrderPresent] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);

  const cancelButtonRef = useRef(null);

  const { isConnected, address } = useAccount();

  //   const checkIfOrderExists = async (targetWalletAddress) => {
  //     try {
  //       const q = query(
  //         collection(ordersCollectionRef),
  //         where("walletAddress", "==", targetWalletAddress)
  //       );
  //       const querySnapshot = await getDocs(q);

  //       return !querySnapshot.empty;
  //     } catch (error) {
  //       console.error("Error checking for order:", error);
  //       throw error; // Re-throw the error for the calling code to handle if necessary
  //     }
  //   };

  useEffect(() => {
    // TODO TEST THIS BECAUSE IT WAS ACTING WEIRD WITH THE OPEN STATE

    if (open) {
      setLoaded(false);
      const checkIfOrderExists = async () => {
        if (isConnected && address) {
          try {
            const q = query(
              collection(db, "orders"),
              where("walletAddress", "==", address)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
              const orderDoc = querySnapshot.docs[0];
              const orderDetails = await getDoc(orderDoc.ref);
              setOrderPresent(true);
              setOrderDetails(orderDetails.data());

              setLoaded(true);
            } else {
              setOrderPresent(false);
              setLoaded(true);
            }

            //   return !querySnapshot.empty;
          } catch (error) {
            console.error("Error checking for order:", error);
            throw error; // Re-throw the error for the calling code to handle if necessary
          }
        }
      };
      checkIfOrderExists();
    }
  }, [open]);

  //   console.log(orderDetails);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed  inset-0 bg-black bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative  transform overflow-hidden rounded-lg bg-[#0f1214] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg py-6">
                {!loaded ? (
                  <div className="flex flex-col items-center px-4 text-lg">
                    <Skeleton height={50} width={50} borderRadius={"9999px"} />

                    <Skeleton height={20} width={130} className="mt-5" />

                    <div className="mt-6">
                      <Skeleton height={15} width={400} count={3} />
                    </div>

                    <div className="mt-8">
                      <Skeleton height={15} width={400} />
                    </div>
                  </div>
                ) : orderPresent && orderDetails.trackingNumber ? (
                  <div className="flex flex-col items-center px-4 text-lg">
                    <div className="mx-auto flex h-12 w-12  items-center justify-center rounded-full bg-green-100 sm:mx-0 ">
                      <TruckIcon
                        className="h-8 w-8 text-green-600"
                        aria-hidden="true"
                      />
                    </div>

                    <div className="font-VitoBold mt-4 text-green-600">
                      Your package has shipped !
                    </div>

                    <p className="font-VitoLight text-white mt-6  text-center ">
                      Something exciting is on its way! Your order for address
                      has been shipped.
                    </p>

                    <div className="font-VitoLight flex justify-between w-full px-2 mt-8 ">
                      <span>Order wallet address:</span>{" "}
                      <span className="font-VitoBold">
                        {shortenAddress(address) || ""}
                      </span>
                    </div>

                    <div className="font-VitoLight flex justify-between w-full px-2 mt-4 ">
                      <span>Tracking No:</span>{" "}
                      <span className="font-VitoBold">
                        {orderDetails.trackingNumber}
                      </span>
                    </div>
                  </div>
                ) : orderPresent ? (
                  <div className="flex flex-col items-center px-6 text-lg">
                    <div className="mx-auto flex h-12 w-12  items-center justify-center rounded-full bg-yellow-100 sm:mx-0 ">
                      <CogIcon
                        className="h-8 w-8 text-yellow-800"
                        aria-hidden="true"
                      />
                    </div>

                    <div className="font-VitoBold mt-4 text-yellow-600">
                      Your order is processing
                    </div>

                    <p className="font-VitoLight text-white mt-6  text-center ">
                      We have received your order and something exciting will
                      soon be on its way! In the meantime please be patient and
                      reach out to us for any inquiries or issues.
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col items-center px-4 text-lg">
                    <div className="mx-auto flex h-12 w-12  items-center justify-center rounded-full bg-red-100 sm:mx-0 ">
                      <ExclamationTriangleIcon
                        className="h-8 w-8 text-red-600"
                        aria-hidden="true"
                      />
                    </div>

                    <div className="font-VitoBold mt-4 text-red-600">
                      No orders found !
                    </div>

                    <p className="font-VitoLight text-white mt-6  text-center ">
                      No orders were found for the connected wallet address.
                      Please ensure you have properly placed your order and
                      contact us for further assistance. Thank you!
                    </p>

                    <div className="font-VitoLight flex justify-between w-full px-2 mt-8 ">
                      <span>Wallet address:</span>{" "}
                      <span className="font-VitoBold">
                        {shortenAddress(address) || ""}
                      </span>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
