import React, { useEffect, useState } from "react";
import Landing from "../../components/Landing/Landing";
import { useAccount } from "wagmi";
import { Addresses } from "../../utils/whitelist/Whitelist";
import ClaimArea from "../../components/ClaimArea/ClaimArea";
import Footer from "../../components/Footer/Footer";
import TrackOrderModal from "../../components/TrackOrderModal/TrackOrderModal";
import TransitionAnimation from "../../components/TransitionAnimation/TransitionAnimation";
// import ShippingForm from "../../components/ShippingForm/ShippingForm";

const Home = () => {
  const [isFamily, setIsFamily] = useState(false);
  const [open, setOpen] = useState(false);
  const [animateTrigger, setAnimateTrigger] = useState(false);
  const [pageTransitioned, setPageTransitioned] = useState(false);

  const { isConnected, address } = useAccount();

  // function isAddressPresent(addressToCheck, addressesArray) {
  //   return addressesArray.includes(addressToCheck);
  // }

  function isAddressPresent(addressToCheck, addressesArray) {
    // Normalize the address to lowercase
    const normalizedAddressToCheck = addressToCheck.toLowerCase();

    // Normalize each address in the array and check for inclusion
    return addressesArray.some(
      (address) => address.toLowerCase() === normalizedAddressToCheck
    );
  }

  useEffect(() => {
    if (isConnected) {
      if (isAddressPresent(address, Addresses)) {
        setIsFamily(true);
        setAnimateTrigger(true);
      } else {
        setIsFamily(false);
        setPageTransitioned(false);
      }
    } else {
      setPageTransitioned(false);
      setIsFamily(false);
    }
  }, [address, isConnected]);

  return (
    <div className="">
      <Landing
        isFamily={isFamily}
        pageTransitioned={pageTransitioned}
        setOpen={setOpen}
      />

      <TransitionAnimation
        animateTrigger={animateTrigger}
        setAnimateTrigger={setAnimateTrigger}
        setPageTransitioned={setPageTransitioned}
      />

      {isConnected && isFamily && pageTransitioned && (
        <div>
          <ClaimArea />
          <Footer setOpen={setOpen} />
          <TrackOrderModal open={open} setOpen={setOpen} />
        </div>
      )}
      {/* <ShippingForm /> */}
    </div>
  );
};

export default Home;
