import React from "react";

const BUTTON_TYPE_CLASSES = {
  fiBtn: "btn-fi text-[14px] font-IBMPlex font-medium",
  claimSection:
    "bg-[#FF0033] text-white font-semibold rounded-xl px-5 py-3 text-xl min-w-[200px] max-w-[250px]",
  claimSectionDisabled:
    "bg-[#FF0033] text-white font-semibold rounded-xl px-5 py-3 text-xl min-w-[200px] max-w-[250px] opacity-[75%]",
  claimSectionBack:
    "bg-black text-white font-semibold rounded-xl px-5 py-3 text-xl max-w-[250px] ",

  claimSectionBackDisabled:
    "bg-black text-white font-semibold rounded-xl px-5 py-3 text-xl max-w-[250px] opacity-[75%] cursor-auto",
};

export const Button = ({ children, buttonType, ...props }) => {
  return (
    <button className={BUTTON_TYPE_CLASSES[buttonType]} {...props}>
      {children}
    </button>
  );
};
