import React from "react";

const INPUT_TYPE_CLASSES = {
  main: "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FF0033] sm:text-sm sm:leading-6 ",
};

const FormField = ({
  label,
  classType,
  inputType,
  inputId,
  autoId,
  ...props
}) => {
  return (
    <div className="w-full">
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
        <div class="mt-2">
          <input
            type={inputType}
            id={inputId}
            autocomplete={autoId}
            className={INPUT_TYPE_CLASSES[classType]}
            spellCheck={false}
            required
            {...props}
          />
        </div>
      </div>
    </div>
  );
};

export default FormField;
<select
  id="country"
  name="country"
  autocomplete="country-name"
  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#FF0033] sm:max-w-xs sm:text-sm sm:leading-6"
>
  <option>United States</option>
  <option>Canada</option>
  <option>Mexico</option>
</select>;
